body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  min-height: 50px;
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  padding: 8px;
  margin-top: 16px;
}

.dropzone-container {
  --bs-border-style: dashed;
  cursor: pointer;
}