.section {
  margin-top: 32px;
}

.form-container {
  width: 100%;
  max-width: 500px;
}

.form-container > div {
  margin-top: 16px;
}

.form-container > :first-child {
  margin-top: 0px;
}

.form-container .card .card-body > div {
  margin-top: 16px;
}

.form-heading {
  padding: 16px;
}

.header {
  padding-top: 16px;
}

.chapter-list {
  display: flex;
  justify-content: flex-start;
}

.add-new-chapter {
  border: 1px dashed;
  border-color: gray;
  min-height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-new-chapter.disabled {
  cursor: not-allowed;
}

.add-new-chapter:not(.disabled):hover {
  background-color: #edf1f5b9;
}
