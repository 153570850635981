.add-new-question:hover {
  background-color: #edf1f5b9;
}
.answers-list > div {
  border-bottom: 1px solid lightgray;
}
.answers-list > :last-child {
  border-bottom: 0;
}
.answer-option {
  margin-left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.answer-option button {
  min-width: unset;
}
.add-answer {
  margin-top: 16px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.add-answer-icon {
  padding: 8px;
  border-radius: 5px;
  background-color: #e8edf2;
  margin-right: 8px;
}
