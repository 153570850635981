.chapter-icon {
  height: 50px;
  width: 50px;
  background-color: #e8edf2;
  padding: 12px;
  border-radius: 8px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chapter-stats {
  margin-top: -4px;
  margin-bottom: 0;
}

.chapter-item {
  padding: 8px;
  border-radius: 5px;
}

.chapter-item h5{
  line-height: 1.7rem;
}

.chapter-item:hover {
  background-color: #edf1f5b9;
  cursor: pointer;
}