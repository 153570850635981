.app-navbar {
  border-bottom: 1px solid #c4c4c4;
  background-color: #5e69ff;
}

.outlet-container {
  padding-top: 32px;
}
.nav-profile-pic {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.outlet-container {
  min-height: 100vh;
}
