.section {
  margin-top: 32px;
}

.form-container {
  width: 100%;
  max-width: 600px;
}

.form-container > div {
  margin-top: 16px;
}

.form-container > :first-child {
  margin-top: 0px;
}

.form-container .card .card-body > div {
  margin-top: 16px;
}

.form-heading {
  padding: 16px;
}

.header {
  padding-top: 16px;
}

.add-new-question {
  border: 1px dashed;
  border-color: gray;
  min-height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-new-question.disabled {
  border: 1px dashed;
  border-color: gray;
  min-height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}

.add-new-question:not(.disabled):hover {
  background-color: #edf1f5b9;
}

.answers-list > div {
  border-bottom: 1px solid lightgray;
}

.answers-list > :last-child {
  border-bottom: 0;
}

.answer-text {
  margin-bottom: 4px;
  margin-top: 4px;
}

.answer-option {
  margin-left: 16px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.answer-option > p {
  margin-top: 1rem;
  flex: 1;
}

.add-answer {
  margin-top: 16px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-answer-icon {
  padding: 16px;
  border-color: gray;
  border-radius: 8px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8edf2;
  margin-right: 4px;
}
