.logo {
  height: 40px;
}

button {
  min-width: 140px;
}

.breadcrumb-item > a {
  text-decoration: none;
}

.logo-text{
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  /* color: rgb(13 110 253); */
  color: white;
}

.unset-button-width{
  min-width: unset;
}