.signin-form-container {
  min-height: 100vh;
  position: relative;
}

.signin-form-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background: url('/public/hero-bg.svg');
  background-repeat: no-repeat;
  z-index: -1;
}

.signin-form {
  padding: 16px;
}

.form-container {
  width: 100%;
  max-width: 400px;
}

.signin-form > div {
  margin-top: 16px;
}

.signin-form > :first-child {
  margin-top: 0px;
}
