.section {
  margin-top: 32px;
}

.tile {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-dashboard-image {
  max-width: 700px;
}

.empty-dashboard-prompt {
  text-align: center;
}