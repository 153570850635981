.course-viewer {
  font-family: "Lato", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.course-viewer .course-title{
  font-weight: 400;
  font-size: x-large;
  color: cyan;
  border-left: 1px solid cyan;
  padding-left: 16px;
  margin-top: 4px;
}

.course-container {
  padding-top: 16px;
  padding-bottom: 16px;

  height: calc(100vh - 69px);
  /* background-color: #f0f0f0; */
}

.course-container .row {
  height: 100%;
}

.curriculum-column {
  border-right: 1px solid #c4c4c4;
}

.ask-column {
  border-left: 1px solid #c4c4c4;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ask-column-layout {
  border-radius: 0px 0px 10px 10px;
  margin-top: 4px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  width: 100%;
  justify-content: flex-end;
  flex: 1;
  overflow-y: scroll;
}

.layout-actions button {
  min-width: unset;
  /* padding: 4px; */
  text-decoration: none;
}

.video-column {
  flex: 1;
  height: 100%;
  overflow-y: scroll;
}

.messages-list {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ask-text-box {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.ask-heading {
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 10px 10px 0px 0px;
}

.ask-text-box button {
  min-width: unset;
  width: 100px;
}

.chat-text-container {
  display: flex;
  margin-bottom: 8px;
}

.chat-text-container p {
  line-height: 1.5rem;
}

.chat-text-container .name {
  font-size: smaller;
}
.chat-text-container .message {
  background-color: #fafafa;
  padding: 8px;
  border-radius: 0px 10px 10px 10px;
  font-size: 0.9rem;
}

.nerdi-avatar {
  width: 55px;
  background-color: lightblue;
  border-radius: 50px;
  padding: 4px;
  margin-right: 8px;
}

.chapters-container {
  margin-bottom: 8px;
}

.chapters-container .accordion-item {
  border: 0;
}

.chapters-container .accordion-button {
  background-color: transparent;
}

.chapters-container .chapter-title {
  font-family: "Lato", sans-serif;
}

.topic-items > div {
  padding: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
}

.topic-items > div:first-child {
  padding-top: 0;
}

.topic-items > div:last-child {
  padding: 8px;
  border-bottom: none;
}

.video {
  width: 100%;
  max-height: calc(100vh - 200px);
}

.description-container {
  padding: 8px;
  padding-left: 0;
}

.chapter-description {
  line-height: 1.7rem;
}
