.edit-menu > a {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.edit-menu a .active {
  color: white !important;
}

.course-outline h6 {
  font-size: small;
  display: inline;
}
.course-outline h6:hover {
  text-decoration: underline;
}

.course-outline > div {
  font-size: small;
}

.course-outline a {
  text-decoration: none;
}

.course-outline ul {
  list-style-type: none;
}

.course-outline li {
  padding: 4px;
  padding-left: 8px;
  margin-left: -30px;
  border-left: 1px solid gainsboro;
}
.course-outline li:hover {
  /* background-color: gainsboro; */
  border-radius: 5px;
  text-decoration: underline;
}

.add-new-chapter.quick-nav-action {
  min-height: 0;
}
